
.circleGroup{
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 24%;
}
.circle1{
    border-radius: 50%;
    background-color: #32CD32;
    position: absolute;
    animation: scaling 2s linear infinite;
    margin-right: 0%;
    animation-delay: 0s;
}
.circle2{
    border-radius: 50%;
    background-color: #32CD32;
    position: absolute;
    animation: scaling 2s linear infinite;
    animation-delay: 1s;
}
.phoneIcon{
    z-index: 2;
    font-size: 4px;
    position: absolute;
    animation: scalingPhone 2s linear infinite;
    animation-delay: 0s;

}
@keyframes scaling {
    0% {
      width: 20px;
      height: 20px;
      background-color: #32CD32;
    }
    100% {
      width: 200px;
      height: 200px;
      background-color: rgba(50,205,50,0);
    }
  }

@keyframes scalingPhone {
    0% {
      width: 20px;
      height: 20px;
    }
    100% {
      width: 50px;
      height: 50px;
    }
}