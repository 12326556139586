.dropzone-root {
  cursor: pointer;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  max-height: 100%;
  padding: 0;
  max-width: 100%;
}

.dropzone-container {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  transition: height 0.2s;
}

.dropzone-content p {
  margin: 0;
  padding: 0;
}

.dropzone-content div {
  text-align: center;
  margin-top: 0px;
}

.upload-icon {
  margin: 8px 0;
  color: #95989b;
  font-size: 40px;
}

.dropzone-file-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.dropzone-file-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.dropzone-file-display {
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: auto;
  max-width: 100%;
}

.delete-button {
  margin-left: 0;
  background: gray;
  border-radius: 50%;
  padding: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 20px;
  height: 20px;
}

.delete-icon {
  color: white;
  padding: 5px;
}
